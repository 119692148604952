LS.validate = {
	/**
	 * Validate data store against ruleset
	 *
	 * @param {Object} data
	 * @param {Object} rules
	 * @param {Object} [options]
	 * @returns {(Array|Boolean)}
	 */
	run: function(data, rules, options) {
		var conf = $.extend({
				firstOnly: true,
				flash: true,
				labels: {}
			}, options),
			names = Object.keys(rules),
			errors = [],
			i = 0;

		for (; i < names.length; i++) {
			var key = names[i],
				name = key,
				checks = $.toArray(rules[key]),
				value = data[key],
				required = checks.indexOf('required');

			if (required > -1) {
				if (required > 0) {
					checks.splice(required, 1);
					checks.unshift('required');
				}

				required = true;
			} else {
				required = false;
			}

			for (var x = 0; x < checks.length; x++) {
				if (! required && ! value) {
					break;
				}

				var parts = checks[x].split(/:(.+)/),
					fn = this.validators[parts[0].replace('data-', '')],
					param = parts.length > 1 ?
						parts[1] : '';

				if (fn.valid.call(data, value, key, param)) {
					continue;
				}

				errors.push(key);

				if (conf.flash) {
					LS.util.warn($.view.render(fn.msg, {
						attribute: LS.util.capitalize(
							conf.labels[name] || name.replace('_', ' ')
						),
						param: param
					}));
				}

				if (conf.callback) {
					conf.callback(key);
				}

				if (conf.firstOnly) {
					return errors;
				}
			}
		}

		return errors.length ?
			errors : true;
	},

	/**
	 * Highlight form element
	 *
	 * @param {$} el
	 * @param {Boolean} [focus=false]
	 */
	highlight: function(el, focus) {
		try {
			var $el = $(el),
				$target = $el,
				invalidClass = '-is-invalid';

			if ($el[0].nodeName === 'SELECT') {
				$target = $el.parent();
			}

			$target.addClass(invalidClass);

			if (focus) {
				$el.focus(true);
			} else {
				LS.util.setScroll($target, true, 50);
			}

			setTimeout(function () {
				$target.removeClass(invalidClass);
			}, 2000);
		} catch (e) {
			//
		}
	},

	/**
	 * Verify pattern
	 *
	 * @param {String} value
	 * @param {String} pattern
	 * @param {Boolean} [exact=false]
	 * @returns {Boolean}
	 */
	verify: function(value, pattern, exact) {
		pattern = this.patterns[pattern];

		if (exact) {
			pattern = '^' + pattern + '$';
		} else {
			pattern = '\\b' + pattern + '\\b';
		}

		return new RegExp(pattern, 'i').test(value.trim());
	},

	/**
	 * Verify email
	 *
	 * @param {String} value
	 */
	isEmail: function(value) {
		return this.verify(value, 'email', true);
	},

	/**
	 * Check if value contain email
	 *
	 * @param {String} value
	 */
	hasEmail: function(value) {
		return this.verify(value, 'email');
	},

	/**
	 * Verify URL
	 *
	 * @param {String} value
	 */
	isUrl: function(value) {
		return this.verify(value, 'url', true);
	},

	/**
	 * Check if value contain URL
	 *
	 * @param {String} value
	 */
	hasUrl: function(value) {
		return this.verify(value, 'url');
	},

	/**
	 * Verify phone
	 *
	 * @param {String} value
	 */
	isPhone: function(value) {
		return /^([()+\d \-\/x*#,.]|ext\.?)+$/i.test(value) &&
			value.replace(/\D/g, '').length >= 10;
	},

	/**
	 * Check if value contains phone
	 *
	 * @param {String} value
	 */
	hasPhone: function(value) {
		return this.verify(value, 'phone');
	},

	/**
	 * Verify mixed case
	 *
	 * @param {String} value
	 */
	isMixedCase: function(value) {
		return /[a-z]/.test(value) &&
			(/[A-Z]/.test(value) || /^\d/.test(value.trim()));
	},

	patterns: {
		email: '[^ ]+@[a-z\\d\\-]+(\\.[a-z]{2,})+',
		phone: '\\d{3}([ .-]|\\) ?)\\d{3}[ .-]?\\d{4}',
		url: '(https?:\\/\\/|www\\.)([a-z\\d\\-.]+)\\.[a-z]{2,}.*'
	},

	validators: {
		required: {
			msg: '{{ attribute }} field is required',
			valid: function(value) {
				return ! (
					value === null ||
					value === undefined ||
					(typeof value === 'string' && value.trim() === '') ||
					(typeof value === 'object' && ! Object.keys(value).length)
				);
			}
		},
		numeric: {
			msg: '{{ attribute }} must be a number',
			valid: function(value) {
				return ! isNaN(parseFloat(value)) && isFinite(value);
			}
		},
		email: {
			msg: '{{ attribute }} must be valid',
			valid: function(value) {
				return LS.validate.isEmail(value);
			}
		},
		tel: {
			msg: '{{ attribute }} must be valid',
			valid: function(value) {
				return LS.validate.isPhone(value);
			}
		},
		url: {
			msg: '{{ attribute }} must be a valid URL',
			valid: function(value) {
				return LS.validate.isUrl(value);
			}
		},
		json: {
			msg: '{{ attribute }} must be valid JSON',
			valid: function(value, key) {
				try {
					this[key] = JSON.parse(value);
				} catch (e) {
					return false;
				}

				return true;
			}
		},
		min: {
			msg: '{{ attribute }} must be {{ param }} or more',
			valid: function(value, key, param) {
				return value >= param;
			}
		},
		max: {
			msg: '{{ attribute }} must be {{ param }} or less',
			valid: function(value, key, param) {
				return value <= param;
			}
		},
		minlength: {
			msg: '{{ attribute }} must contain at least {{ param }} characters',
			valid: function(value, key, param) {
				return value.length >= param;
			}
		},
		maxlength: {
			msg: '{{ attribute }} must contain at most {{ param }} characters',
			valid: function(value, key, param) {
				return value.length <= param;
			}
		},
		pattern: {
			msg: '{{ attribute }} is invalid',
			valid: function(value, key, param) {
				if (! value.trim()) {
					return true;
				}

				return (new RegExp('^' + param + '$')).test(value);
			}
		},
		case: {
			msg: '{{ attribute }} must be "{{ param }} {{ param|is("title") ? "C" : "c" }}ase"',
			valid: function(value) {
				return LS.validate.isMixedCase(value);
			}
		}
	}
};